import store from 'store'
import { getToken } from '@/utils/auth' // getToken from cookie
import router from '@/router'
router.beforeEach((to, from, next) => {
  if (getToken()) {
    if (store.getters.roles.length === 0) {
      // 判断当前用户是否已拉取完user_info信息
      store.dispatch('GetInfo').then(res => { // 拉取user_info
        next()
      }).catch(() => {
        store.dispatch('FedLogOut').then(() => {
          next({ path: '/' })
        })
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

router.afterEach(() => {
})
