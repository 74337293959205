const linkBase = process.env.VUE_APP_RUNTIME === 'development' ? '/admin.html#' : '/pcb'
// 跳转到内页
export const linkInsidePages = (route) => {
  const queryKeys = Object.keys(route.query || {})
  let query = queryKeys.length ? '?' : ''
  queryKeys.forEach((item, index) => {
    query += `${item}=${route.query[item]}`
    if (index !== queryKeys.length - 1) {
      query += '&'
    }
  })
  window.location.href = `${linkBase}${route.path}${query}`
}
